<template>
  <div>
    <modal
      ref="modal"
      class-name="duration-100 flex flex-col h-600px pt-12 overflow-hidden w-full md:w-500px 2xl:w-600px"
    >
      <form
        class="flex flex-col flex-grow"
        novalidate
        @submit.prevent="submit"
      >
        <h6 class="mb-6">
          Basic Account Verification
        </h6>

        <p class="font-thin mb-6">
          Kindly verify your account to continue
        </p>

        <label for="date_of_birth" class="mb-3 text-sm">
          Date of birth
        </label>
        <form-group
          v-model="form.data.date_of_birth.value"
          name="date_of_birth"
          :form="form"
          type="date_of_birth"
        >
          Date Of Birth
        </form-group>

        <form-group
          v-model="form.data.bvn.value"
          name="bvn"
          :form="form"
          class="mb-0"
        >
          BVN
        </form-group>

        <div class="flex mt-3 mb-6">
          <div class="flex">
            <ion-icon name="shield" class="text-green-500 mr-4 text-2xl"></ion-icon>
            <div class="text-sm mr-2">
              We’re mandated by CBN to keep your BVN and other vital information highly secured and confidential.
            </div>
          </div>
          <div
            class="text-2xs font-bold whitespace-no-wrap cursor-pointer text-right mt-2"
            @click.prevent="$refs.bvnHelpModal.open"
          >
            Forgot BVN?
          </div>
        </div>

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <div class="flex gap-4 mt-auto">
          <button
            type="submit"
            class="btn btn-blue btn-md mt-auto relative w-full"
            :disabled="form.loading"
          >
            <sm-loader
              v-if="form.loading"
              class="sm-loader-white"
            />
            <span v-else>Proceed</span>
            <i
              class="absolute inline-flex right-20px top-1/2"
              style="transform: translateY(-50%);"
            >
              <ion-icon name="arrow-forward-outline" />
            </i>
          </button>
        </div>
      </form>
    </modal>

    <modal className="w-full md:w-450px" ref="bvnHelpModal">
      <h1 class="text-base md:text-xl font-bold mb-6">
        Need help finding out your BVN?
      </h1>

      <div class="border border-blue-200 rounded-sm mb-6 text-center">
        <div class="px-6 py-4 border-b border-blue-200">
          Just dial <span class="font-mono font-bold pt-2 pb-2 px-3 bg-blue-200 rounded-sm" style="letter-spacing: 1px">
            *565*0#
          </span>
        </div>
        <div class="px-6 py-4">
          This service would cost <span class="font-mono font-bold text-red-800" style="letter-spacing: 1px">
            ₦20
          </span>
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn btn-blue btn-md"
          @click.prevent="$refs.bvnHelpModal.close"
        >
          Okay
        </button>
      </div>

    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'IdentityVerification',
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'bvn', rules: 'required|number|length:11' },
        'date_of_birth',
      ]),
    };
  },
  mounted() {
    this.$emit('mounted');
    this.mapData();
  },
  methods: {
    ...mapActions('session', ['updateUser']),

    close() {
      this.$refs.modal.close();
    },
    mapData() {
      this.form.data.bvn.value = this.user?.profile?.bvn || '';
      this.form.data.date_of_birth.value = this.user?.profile?.date_of_birth || '';
    },
    open() {
      this.$refs.modal.open();
    },

    async submit() {
      if (!this.validateForm(this.form)) {
        return;
      }

      this.form.error = false;
      this.form.loading = true;

      await this.sendRequest('personal.onboarding.identityVerification', {
        data: this.getFormData(),
        success: (response) => {
          const { data: { user, profile } } = response.data;
          this.updateUser({ ...user, profile })
            .then(() => {
              this.close();
              this.$emit('complete-identity-verification');
            });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      })
      .finally(() => {
        this.form.loading = false;
      });
    },
  },
};
</script>
